.logoImage {
  width: 120px;
  height: 45px;
  float: left;
  margin-right: 10px;
}

.dashboard_menubar {
  float: left;
  height: 100%;
  margin: 0px 15px;
}

.dashboard_container button:focus{
outline: none;
border: none;
}

.dashboard_container button:active{
  outline: none;
}
.MuiDrawer-paper {
  top: 64px !important;
}

.dashboard_body {
  height: calc(100vh - 270px);
  /*overflow: auto;*/
}

#dashboard_drawer {
  background: red;
}

#dashboard_drawer > .MuiDrawer-paperAnchorLeft {
  top: 64px !important;
}

.dashboard_loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.dashboard_menu_carat {
  flex-direction: column;
  margin: 5px;
}

.dashboard_menu_carat_name {
  font-style: oblique;
}

.dashboard_menu_carat_role {
  text-align: center;
  font-size: small;
}
