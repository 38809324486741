.filter_drawer_btn {
  
  width: 100%;
  bottom: 0;
  padding: 10px;
  
  display: flex;
  justify-content: flex-end;
}

li{color: black;}