.listCompany_header {
  height: 60px;
  background: #ffffff;
  box-shadow: 2px 0px 10px grey;
}

.listCompany_header_title {
  font-size: 23px;
  padding: 10px 15px;
}

.listCompany_header_button {
  float: right;
  margin: 5px;
}

.listCompany_body_background {
  background: #0f183f3b;
  height: calc(100vh - 168px);
}

.listCompany_body_content {
  overflow: auto;
  height: calc(100vh - 185px);
  margin-top: 10px;
  padding: 5px;
}

.listCompany_body_content_company {
  margin: 10px;
  height: 45px;
  padding: 10px;
}

.listCompany_body_content_company_btn {
  float: right;
  padding-right: 10px;
}
