/*Rule css start*/
.processAnalyzer_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.processAnalyzer_body_row {
  margin-top: 10px;
}

.processAnalyzer_body_background {
  background: #0f183f3b;
  margin: 0;
}

.processAnalyzer_header {
  height: 90px;
  background: #ffffff;
  box-shadow: 2px 0px 10px grey;
  margin: 0;
}

.processAnalyzerDrawer_header {
  background: #ffffff;
  box-shadow: 2px 0px 10px grey;
}

.processAnalyzer_header_title {
  height: 50px;
  font-size: 23px;
  padding: 20px 0px;
}


div.vertical-line{
  width: 1px;
    background-color: #edeeed;
    /* height: 100%; */
    float: none;
    display: inline-block;
    margin: auto 10px;
}

.processAnalyzer_header_title span{
  display: block;
  float: left;
}

.processAnalyzer_header_title_drawer {
  font-size: 23px;
  padding-top: 10px;
}

.processAnalyzer_header_title_subheader_drawer{
  font-size: small;
  padding: 5px 0px;
  font-weight: 500;
  display: flex;
}

.processAnalyzer_header_title_subheader_Remedy1{
  flex-direction: row;
}

#MUIRichTextEditor_ReadOnlyUI99-root > div {
  flex-direction: row;
  margin-top: -4px;
  font-size: small;
    padding: 0px 5px;
    font-weight: 500;
    display: flex;
}

#MUIRichTextEditor_ReadOnlyUI88-root > div {
  font-size: small;
  font-weight: bold;
  margin: 0px;
}


.filenameSpan {
    width: 27%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 2%;
}


.processAnalyzer_header_button {
  float: right;
  margin: 5px;
}

.processAnalyzer_stepper_body {
  padding: 5px 15px;
  height: calc(100vh - 155px);
  overflow: auto;
}

.processAnalyzer_stepper_body_row {
  margin-top: 10px;
}
.processAnalyzer_stepper_title {
  font-size: 23px;
  padding: 10px 0px;
}

.processAnalyzer_stepper_button {
  margin: 5px;
  /* width: 100%; */
  position: absolute;
  right: 0px;
  display: block;
}

.processAnalyzer_stbtn {
  /* display: block; */
  width: 100%;
  margin: 10px 0px;
  text-align: right;
}

.processAnalyzer_stepper_body_row {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: -5px;
  margin-left: -5px;
}

.custom-card {
  border: 1px solid rgb(182, 178, 178);
  width: 98%;
  margin-left: 0.7rem;
  border-radius: 5px;
}
.custom-card-header {
  color: black;
  font-weight: 500;
  width: 100%;
  height: 2.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgb(182, 178, 178);
}

.custom-card-body {
  background-color: #f4f5f4;
  padding: 0.5rem 1rem;
}

#tooltipHeader {
  display: block;
  color: white;
  background: black;
  float: left;
  margin-left: 10px;
  padding: 1px 10px;
  border-radius: 20px;
  text-align: center;
}

#processAnalyzerCreate_drawer > .MuiDrawer-paperAnchorRight {
  top: 64px !important;
  height: calc(100vh - 64px) !important;
  width: 30%;
  /* max-height: 50%; */
}

#processAnalyzerCreate_drawer > .MuiBackdrop-root {
  top: 64px !important;
}
/*Rule css end*/

#processAnalyzerCreate_drawer > .MuiDrawer-paperAnchorBottom {
    /* max-height: 80%;
    overflow: hidden; */
}