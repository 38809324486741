.rule_create_right_main {
  height: auto;
}

.rule_create_right_body {
  padding: 15px;
}

.rule_create_right_body_row {
  padding: 5px;
}
.rule_create_right_body_subheader {
  padding: 5px;
  color: #b4b5b3;
}
.rule_create_right_body_subcontent {
  padding: 5px;
}

.rule_create_left_main {
  height: calc(100vh - 300px);
  /* padding: 35% 10%; */
}
/* 
.rule_create_left_main {
  height: calc(100vh - 300px);
  padding: 35% 10%
}

.rule_create_left_main {
  height: calc(100vh - 300px);
  padding: 35% 10%
}

.rule_create_left_main {
  height: calc(100vh - 300px);
  padding: 35% 10%
}

.rule_create_left_main {
  height: calc(100vh - 300px);
  padding: 35% 10%
} */

.rule_create_left_body {
  font-size: 23px;
  text-align: center;
  margin: 15px;
}

.rule_create_left_image {
  text-align: center;
  height: 190px;
  width: 100%;
}
