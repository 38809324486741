.addCompany_Row_header {
  background-color: #0f183f;
  display: flex !important;
  justify-content: space-between;
  padding: 15px;
  color: white;
  font-weight: 500;
}
.addCompany_Row_body {
  margin: 30px 20px;
}

.addCompany_Row_button {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}

.addCompany_Row_error {
  color: red;
  margin: 10px 25px;
}
