.analyzer_list_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.analyzer_list_body_row {
  margin-top: 10px;  
}


.analyzer_list_header {
  height: 60px;
  background: #ffffff;
}

.analyzer_list_header_title {
  font-size: x-large;
  padding: 10px;
}

.analyzer_list_header_button {
  float: right;
  margin: 5px;
}

.analyzer_list_paper_noData_body {
  text-align: center;
  background-color: white;
  height: calc(100vh - 145px);
  margin-top: 10px;
}

.analyzer_list_paper_noData_body_image {
  height: 125px;
  margin-top: 10%;
}

.analyzer_list_paper_noData_body_content {
  color: #b4b5b3;
  font-size: 23px;
  padding: 15px;
}

/* Display rules */

.analyzer_list_paper_text {
  font-size: 1.8rem;
  color: black;
  font-weight: 500;
  padding: 0.8rem 1rem !important;
}
.analyzer_list_paper_desc {
  font-size: 1rem;
  color: black;
  padding: 0rem 1rem 0.5rem 1rem;
}
.analyzer_list_paper_info {
  padding: 0rem 1rem 0rem 1rem;
  font-size: 1rem;
  color: black;
}
.analyzer_list_paper_info_subcontent {
  padding: 0rem 1rem 0rem 1rem;
  font-size: 1rem;
  color: black;
  font-weight: 500;
}
.analyzer_list_paper_link {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 1rem !important;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.analyzer_list_paper_link a {
  text-decoration: underline !important;
}

.analyzer_list_body_main {
  overflow: auto;
  padding: 8px !important;
  height: calc(100vh - 124px);
}

.analyzer_list_body_tile_row {
  padding-right: 15px;
  padding-left: 15px;
}
