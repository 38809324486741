.analyzer_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.analyzer_body_row {
  margin-top: 10px;
}

.analyzer_header {
  padding: 10px;
  height: 60px;
  background: #ffffff;
  box-shadow: 2px 0px 10px grey;
}

.analyzer_header_title {
  font-size: 23px;
  padding: 10px 0px;
}

.analyzer_header_button {
  float: right;
  margin: 5px;
}

.analyzer_body_background {
  background: #0f183f3b;
}

.analyzer_stepper_body {
  margin-top: 10px;
  padding: 5px 15px;
  height: calc(100vh - 155px);
  overflow: auto;
}

.analyzer_stepper_body_row {
  margin-top: 10px;
}
.analyzer_stepper_title {
  font-size: 23px;
  padding: 10px 0px;
}

.analyzer_stepper_button {
  float: right;
  margin: 5px;
}

.analyzer_stepper_body_row {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: -5px;
  margin-left: -5px;
}

#analyzerCreate_drawer > .MuiDrawer-paperAnchorRight {
  top: 64px !important;
  height: calc(100vh - 64px) !important;
}

#analyzerCreate_drawer > .MuiBackdrop-root {
  top: 64px !important;
}

#analyzerViewInfo_drawer > .MuiDrawer-paperAnchorRight {
  top: 64px !important;
  height: calc(100vh - 64px) !important;
  width: 30%;
}

#analyzerViewInfo_drawer > .MuiBackdrop-root {
  top: 64px !important;
}
