.procees_list_body {
  padding: 10px 0px;
  height: calc(100vh - 130px);
  overflow: auto;
}

.procees_list_body_row {
  margin-top: 10px;
}

.procees_list_header {
  height: 60px;
  background: #ffffff;
}

.procees_list_header_title {
  font-size: x-large;
  padding: 10px;
}

.procees_list_header_title_sub {
  font-size: small;
  font-weight: 500;
}

.procees_list_header_title_subgreay {
  font-size: 14px;
  font-weight: 500;
  color: gray;
}

.procees_list_header_title_counter {
  font-size: 14px;
  font-weight: 500;
  color: #eabd00;
}

.MuiFormControl-root.MuiOutlinedInput-notchedOutline {
  color: blue;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #43b0f1;
}

.procees_list_header_title_subred {
  font-size: small;
  font-weight: 500;
  color: red;
  margin-left: 8px;
}

.procees_list_header_title_subgreen {
  font-size: small;
  font-weight: 500;
  color: green;
  margin-left: 8px;
}

.procees_list_header_button {
  float: right;
  margin: 5px;
}

.procees_list_noData_body {
  text-align: center;
  background-color: white;
  height: calc(100vh - 160px);
  margin-top: 10px;
  width: 100%;
}

.procees_list_noData_header {
  text-align: center;
  background-color: white;
  width: 98%;
  height: 64px;
  margin-top: 10px;
  border-radius: 12px;
}

.procees_list_noData_body_image {
  height: 125px;
  margin-top: 10%;
}

.procees_list_noData_body_content {
  color: #b4b5b3;
  font-size: 23px;
  padding: 15px;
}

/* Display rules */

.procees_list_body_main {
  overflow: auto;
  height: calc(100vh - 124px);
}

.procees_list_paper_right_main {
  height: calc(100vh - 150px);
  padding: 10px 15px;
}

.procees_list_paper_left_main {
  height: 50px;
  padding: 12px;
  margin-top: 5px;
  font-weight: 500;
}

.procees_list_paper_left_main_content {
  cursor: pointer;
  height: calc(100vh - 240px);
}

.procees_list_paper_left_redDot {
  margin-top: 5px;
  background-color: red;
  border-radius: 60%;
  height: 12px;
  width: 12px;
  display: inline-flex;
  float: right;
}

.procees_list_paper_left_greenDot {
  margin-top: 5px;
  background-color: green;
  border-radius: 60%;
  height: 12px;
  width: 12px;
  display: inline-flex;
  float: right;
}

.procees_list_paper_left_main_border {
  margin-top: 5px;
  border: 2px solid #0f183fed;
}

.procees_list_paper_right__btn {
  position: absolute;
  width: 95%;
  bottom: 0;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}

.procees_list_paper_right_header_prop {
  font-size: x-large;
  color: grey;
}

.procees_list_paper_right_header_cont {
  font-size: x-large;
}

.procees_list_paper_right_subheader_prop {
  font-size: small;
  color: grey;
  font-weight: 500;
}

.procees_list_paper_right_subheader_cont {
  font-size: small;
  font-weight: 500;
}

.procees_list_paper_right_subheader_contResultRed {
  font-size: small;
  font-weight: 500;
  background-color: red;
  color: white;
  border-radius: 15px;
  padding: 2px 10px;
  margin: 5px;
}

.procees_list_paper_right_subheader_contResultGreen {
  font-size: small;
  font-weight: 500;
  background-color: green;
  color: white;
  border-radius: 15px;
  padding: 2px 10px;
  margin: 5px;
}

.procees_list_paper_right_message_main {
  font-size: small;
  font-weight: 500;
  width: 50%;
}

.procees_list_paper_right_message_header {
  margin-top: 5px;
  border-radius: 3px;
  border: 2px solid #cac7c7;
  height: 30px;
  padding: 5px;
  margin-left: 5px;
}

.procees_list_paper_right_message_greenbody {
  overflow: auto;
  padding: 5px;
  background-color: #00ff0030;
  border-radius: 3px;
  border-left: 2px solid green;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  height: 225px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.procees_list_paper_right_message_redbody {
  overflow: auto;
  padding: 5px;
  background-color: #ff000040;
  border-radius: 3px;
  border-left: 2px solid red;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
  height: 225px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.procees_list_paper_right_message {
  overflow: auto;
  padding: 5px;
  height: 225px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.procees_list_paper_right_subheader_main {
  font-size: small;
  font-weight: 500;
}

.procees_list_paper_right_subheader_Descrip {
  margin-top: 5px;
  border-radius: 3px;
  border: 2px solid #cac7c7;
  height: 30px;
  padding: 5px;
}

.procees_list_paper_right_subheader_contDescripGreen {
  overflow: auto;
  padding: 5px;
  background-color: #00ff0030;
  border-radius: 3px;
  border-left: 2px solid green;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  height: 65px;
}

.procees_list_paper_right_meta_main {
  font-size: small;
  font-weight: 500;
  width: 100%;
}

.procees_list_paper_right_meta_header {
  margin-top: 5px;
  border-radius: 3px;
  border: 2px solid #cac7c7;
  height: 30px;
  padding: 5px;
}

.procees_list_paper_right_meta_greenbody {
  overflow: auto;
  padding: 5px;
  background-color: #00ff0030;
  border-radius: 3px;
  border-left: 2px solid green;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  height: 225px;
  margin-bottom: 5px;
}

.procees_list_paper_right_meta_redbody {
  overflow: auto;
  padding: 5px;
  background-color: #ff000040;
  border-radius: 3px;
  border-left: 2px solid red;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
  height: 225px;
  margin-bottom: 5px;
}

.procees_list_paper_right_display {
  display: flex;
}

.procees_list_paper_right_msghead {
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px dashed;
  font-weight: bold;
}

.procees_list_paper_right_msgsubhead {
  font-weight: bold;
}

.procees_list_paper_right_msgheaderCenterStart {
  font-weight: bold;
  text-align: center;
}

.procees_list_paper_right_msgheaderCenterEnd {
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px dashed;
  font-weight: bold;
  text-align: center;
}

.procees_list_chart_main_div {
  background-color: white;
  border-radius: 5px;
  text-align: center;
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
}

.procees_list_chart_main_head {
  font-size: 23px;
  font-weight: 500;
  padding: 0px 10px;
}

.procees_list_chart_main_total {
  font-size: medium;
  font-weight: 500;
  font-style: italic;
}

.procees_list_chart_main_subhead {
  font-size: 20px;
  padding: 0px 10px;
}

.procees_list_chart_main_subhead_spliter {
  /* border-top: 2px dotted; */
  padding: 10px;
  font-size: 20px;
  padding: 0px 10px;
}

.overall_chart_div {
  border-right: 2px dotted;
}
