/*User css start*/
.users_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.users_body_row {
  margin-top: 10px;
}

.users_body_background {
  background: #0f183f3b;
}

.users_header {
  height: 60px;
  background: #ffffff;
  box-shadow: 2px 0px 10px grey;
}

.users_header_title {
  font-size: 23px;
  padding: 10px 0px;
}

.users_header_button {
  float: right;
  margin: 5px;
}

.users_stepper_body {
  padding: 5px 15px;
  height: calc(100vh - 155px);
  overflow: auto;
}

.users_stepper_body_row {
  margin-top: 10px;
}
.users_stepper_title {
  font-size: 23px;
  padding: 10px 0px;
}

.users_stepper_button {
  float: right;
  margin: 5px;
}

.users_stepper_body_row {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: -5px;
  margin-left: -5px;
}


#userList_drawer > .MuiDrawer-paperAnchorRight {
    top: 64px !important;
    height: calc(100vh - 64px) !important;
  }
  
#userList_drawer > .MuiBackdrop-root {
    top: 64px !important;
  }