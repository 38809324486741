.login {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 10px;
  border: 2px solid #0f183f;
  box-shadow: 0px 8px 25px 0 rgba(0, 0, 0, 0.2);
}

.loginScreenRightSideHeroText {
  color:white;
  font-size:1.3rem;
}

.login_image {
  width:24%;
}

.benefitsList {
  padding:0;
  margin:0;
  list-style-type: disc;
  margin-left: 16%;

}

.benefitsList li {
  color:white;
  font-family: "verdana";
  font-size: 14px;
  padding-bottom: 0px;
  line-height: 14px;
  padding-left: 30px;
  font-size: 12px;
  font-family: "Roboto";
  padding-left: 4px;
  height: 18px;

}
/* .benefitsList li span {
  font-size: 12px;
  font-family: "Roboto";
  padding-left: 4px;
}

.benefitsList li::before {
    content: "•";
    color: white;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
} */

.login_heading {
  text-align: center;
  font-size: xx-large;
  margin: 20px 0px;
}

.login_input {
  text-align: center;
  margin: 25px 0px;
}

.login_button {
  text-align: center;
  margin: 10px 0px;
}

.login_row {
  height: 100vh !important;
}

.login_col1 {
  position: relative;
  z-index: -99;
}


.login_error {
  color: #ff0000;
  text-align: center;
}

@media screen and (max-width: 468px) {
  .login_image {
    width:60%;
  }
}




.LoginPage {
  background-color: rgb(237, 238, 237) !important;
}


