.LoginPage {
  background-color: rgb(237, 238, 237) !important;
  height: 100%;
  padding: 1% 2%;
}

.ContainerBox {
  background-color: rgb(15, 24, 63);
  border-radius: 25px;
  height: 100%;
}


.whiteContainer {
  background-color: white;
  border-radius: 0px 25px 25px 0px;
  margin-left: 0%;
}

.logoImg {
  display: block;
  margin: 0 auto;
  height: 80px;
  margin-top: 2%;
}


.center-align {
  display: block;
  margin: 0 auto;
  color: white;
  text-align: center;
}


.head1 {
  margin-top: 3%;
  font-size: 26px;
}


.head2 {
    color: #3292e0;
    font-size: 18px;
    margin-top: 10px;
    padding-bottom: 30px;
}


.blueprismLogo {
  display: block;
  margin: 0 auto;
  width: 80%;
  height: 95px;
}





.blueBlock {
  padding: 0 17.5% ;
}


@media only screen and (min-width: 	990px) {
  .blueprismLogo {
    position: absolute;
    right: 0;
    width: 80%;
    height: 95px;
  }

  .text1 {
    color: white;
    font-weight: 600;
    display: block;
    margin: 0 auto;
  }
  
  .text2 {
    color: white;
    font-size: 12px;
    text-align: justify;
    display: block;
    margin: 0 auto;
  
  }
}


.text1 {
  color: white;
  font-weight: 600;
}

.text2 {
  color: white;
  font-size: 12px;
  text-align: justify;
}



.featureBlock {
  color: white;
  width: 65%;
  margin: 10% auto;
  border-radius: 15px;
  border: 1px dotted white;


  border: 1px dotted white;
  /* padding: 5px; */
  padding-bottom: 12px;
  background-color: #0051b5;
  padding-top: 12px;
}

.featureBlock ul li{
  color: white;
  font-size: 14px;
  padding: 1px;
}

.featureBlock ul {
  padding: 10px;
  margin-left: 30px;
}


.featureBlock .text1 {
  color: white;
  font-weight: 600;
  margin-left: 4%;
}


.logoBlock {
  text-align: center;
  margin-top: 18%;
}

.logoBlock h1 {
  margin-top: 1%;
  font-size: 32px;
}

.logoBlock img {
  display: block;
  margin: 0  auto;
}