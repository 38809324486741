.drawer_head {
  background-color: #0f183f;
  display: flex !important;
  justify-content: space-between;
  padding: 15px;
  color: white;
  font-weight: 500;
}
.drawer_body {
  padding: 1rem;
}
.drawer_btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}
