.processAnalyzerCreate_drawer_head {
  background-color: #0f183f;
  display: flex !important;
  justify-content: space-between;
  padding: 15px;
  color: white;
  font-weight: 500;
}
.processAnalyzerCreate_drawer_body {
  padding: 1rem;
}
.processAnalyzerCreate_drawer_body_row {
  padding: 10px;
}
.processAnalyzerCreate_drawer_btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}

.processAnalyzerCreate_drawer_body_heading {
  font-weight: bold;
}

.processAnalyzerCreate_drawer_body_heading_body {
  font-weight: bold;
  border-bottom: 1px solid #0f183f;
}

.processAnalyzerCreate_paper_body {
  height: calc(100vh - 245px);
  overflow: auto;
}

.processAnalyzerCreate_paper_subcontent {
  border-bottom: 1px solid #0f183e2b;
  margin-bottom: 5px;
}

.processAnalyzerCreate_paper_subcontent_rule {
  font-size: small;
  color: gray;
}
