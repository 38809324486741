

.listUser_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.listUser_body_row {
  margin-top: 10px;
}

.listUser_header {
  height: 60px;
  background: #ffffff;
}

.listUser_header_title {
  font-size: x-large;
  padding: 10px;
}

.listUser_header_button {
  float: right;
  margin: 5px;
}