html *{
  font-family: 'Roboto' !important;
}

.analyzerCreate_drawer_head {
  background-color: #0f183f;
  display: flex !important;
  justify-content: space-between;
  padding: 15px;
  color: white;
  font-weight: 500;
}
.analyzerCreate_drawer_body {
  padding: 1rem;
}
.analyzerCreate_drawer_btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}
.analyzerCreate_paper_body{
  /* overflow: scroll; */
  
   max-height: 100%;
   overflow-x: hidden;
   border-bottom-left-radius: 12px;
   border-bottom-right-radius: 12px;
  background-color: #f4f5f4;
  overflow-y: scroll;
}

.analyzerCreate_body_row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.analyzerCreate_paper {
  padding: 5px;
}


.analyzerCreate_paper_disabled {
  padding: 5px;
  background-color: lightgray;

}

.analyzerCreate_paper_row {
  padding: 10px;
}



.analyzerCreate_paper_row_disabled {
  padding: 10px;
  background-color: lightgray;

}


.analyzerCreate_body {
  padding-top: 10px;
  height: 80vh;
}

.analyzerCreate_body_background {
  background: #0f183f3b;
  padding-bottom: 16px;
  height: 80vh;
}

.analyzerCreate_paper_body_header_height{
  margin-bottom: 16px;
}
.analyzerCreate_paper_body_header_height_tag_line{
  margin-bottom: 8px;
}
.analyzerCreate_paper_body_height {
  height: 61vh;
  padding: 16px 16px 0 16px;
}

.analyzerCreate_paper_body_descp {
  color: #808080b8;
}

.analyzerCreate_paper_body_header {
  font-size: 18px;
}


.analyzerCreate_categoriesBlock{
  padding: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  height: 48px;
  color: #ffffff;
  background-color: #648cbd;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.analyzerCreate_category, .analyzerCreate_selectedCategory{
  display: inline-block;
  flex: auto;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}
.analyzerCreate_selectedCategory > span{
  border-bottom: 5px solid #ffffff;
  padding: 13px;
}

.analyzerCreate_categoriesBlockSecond{
  padding: 16px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0 !important;
  height: 35px;
  color: #000000;
}
.analyzerCreate_tag_line_padding_top_bottom{
  padding:10px
}

.cardMiddleContentDescriptonBlock{
  height: 55px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}