.rule_defination_image {
  position: absolute;
  height: calc(100vh - 105px);
  width: 90%;
}

.rule_defination_creation {
  margin: 25px 0px;
}
.rule_defination_creation_buttons {
  margin: 25px 90px;
  float: right;
}
