/*Rule css start*/
.rules_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.rules_body_row {
  margin-top: 10px;
}

.rules_body_background {
  background: #0f183f3b;
}

.rules_header {
  height: 60px;
  background: #ffffff;
  box-shadow: 2px 0px 10px grey;
}

.rules_header_title {
  font-size: 23px;
  padding: 10px 0px;
}

.rules_header_button {
  float: right;
  margin: 5px;
}

.rules_stepper_body {
  padding: 5px 15px;
  height: calc(100vh - 155px);
  overflow: auto;
}

.rules_stepper_body_row {
  margin-top: 10px;
}
.rules_stepper_title {
  font-size: 23px;
  padding: 10px 0px;
}

.rules_stepper_button {
  float: right;
  margin: 5px;
}

.rules_stepper_body_row {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: -5px;
  margin-left: -5px;
}

.custom-card {
  border: 1px solid rgb(182, 178, 178);
  width: 98%;
  margin-left: 0.7rem;
  border-radius: 5px;
}
.custom-card-header {
  color: black;
  font-weight: 500;
  width: 100%;
  height: 2.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgb(182, 178, 178);
}

.custom-card-body {
  background-color: #f4f5f4;
  padding: 0.5rem 1rem;
}

#tooltipHeader {
  display: block;
  color: white;
  background: black;
  float: left;
  margin-left: 10px;
  padding: 1px 10px;
  border-radius: 20px;
  text-align: center;
}

#ruleList_drawer > .MuiDrawer-paperAnchorRight {
  top: 64px !important;
  height: calc(100vh - 64px) !important;
}

#ruleList_drawer > .MuiBackdrop-root {
  top: 64px !important;
}

#ruleListFilter_drawer > .MuiDrawer-paperAnchorLeft {
  width: 20%;
  top: 125px !important;
  height: calc(100vh - 124px);
}

#ruleListFilter_drawer > .MuiBackdrop-root {
  top: 125px !important;
}
/*Rule css end*/
