.drawer_head {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: flex !important;
  justify-content: space-between;
  padding: 15px;
  padding: 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdddc;
}
.drawer_box_main{
  background-color: #f4f4f4;
  height: calc(100vh - 130px);
  padding: 8px;
}
.drawer_firstBox{
  border-radius: 12px;
  box-shadow: 0 2px 2px 0 #888080;
  height: auto;
  overflow: hidden;
}
.drawer_body_ruleTitle > span {
  background-color: #0f183f;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
  padding: 16px; 
  display: block;
  align-items: center;
  min-height: 75px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.drawer_body_ruleDescription > span {
  background-color: #ffffff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  padding: 16px; 
  display: block;
  align-items: center;
  min-height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
 
.drawer_btn {
  position: absolute;
  width: 90%;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}

.drawer_btn button{
  border-radius: 4px;
  background-color: #00519f;
  font-family: Roboto;
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.drawer_btn button:hover{
  background-color: #00519f;
}


/* New drawer Design Enhancement: START*/

.drawerMainBox .deactivateddrawer{
  background-color: #dedede;
  opacity: 0.3;
}
.drawerContent{
  border-radius: 12px;
  overflow: hidden;
  margin-top: 8px;
  border-radius: 12px;
  box-shadow: 0 2px 2px 0 var(--black-30);
  background-color: #ffffff;
  height: auto;
}
.drawerContent hr{
  margin-top: 0 !important;
  margin-bottom: 0 !important; 
}
.drawerheaderContent {
  padding: 0px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  height: 48px;
  background-color: #648cbd;
  list-style-type: none;
}
.drawerheaderContent li{
 margin-right: 10px;
 cursor: pointer;
 color: white !important;
 padding: 10px;
}
.selected{
  border-bottom: 5px solid #ffffff;
}
.drawerheaderText{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.04rem;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.drawerMiddleContent{
  padding: 5px 16px;
    box-shadow: 0px 2px 2px 0 #888080;
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #ddd;
}
.drawerMiddleContentBlock{
  padding: 4px 8px;
  border-bottom: 1px solid #dcdddc;
}
.drawerMiddleContentBlockTitle {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: block;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #919294;
} 
.drawerMiddleContentBlockValue{
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.02rem;
  color: #000000;
}
.drawerFooterContent {
  padding: 16px;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e8edf3;
  height: 56px;
}
.drawerFooterContent a {
  text-decoration: underline !important;
}
.drawerFooterContentIcon{
  position: absolute;
  right: 20px;
}
.MuiPaper-rounded{
  border-radius: 12px;
}

.tab {
  /* min-height:50px; */
  width:100%;
  font-family:sans-serif;
  color:#444;
  position: relative;
  left: 0;
  right: 0;
  height: calc(100vh - 412px);
  max-height: 390px;
  overflow-y: auto;
}

.RuleDrawer ul.inline{
  list-style:none;
  padding: 0;
  margin-bottom:0 !important;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.RuleDrawer li{
  display:inline-block;
  margin-left:0;
  padding:10px;
  transition: all .5s;
  cursor:pointer;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.RuleDrawer li > .selected{
  border-bottom:2px solid #337ab7;
  color:#444;
}
/* New drawer Design Enhancement: END*/


.drawerTab {
  height: auto !important;
}