

.listTemplate_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.listTemplate_body_row {
  margin-top: 10px;
}

.listTemplate_header {
  height: 60px;
  background: #ffffff;
}

.listTemplate_header_title {
  font-size: x-large;
  padding: 10px;
}

.listTemplate_header_button {
  float: right;
  margin: 5px;
}

.rule_link {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 1rem !important;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rule_link a {
  text-decoration: underline !important;
}