.profile_Row_header {
  margin: 10px 10px;
}
.profile_Row_body {
  margin: 10px 10px;
}
.profile_Row_error {
  color: red;
  margin: 10px 25px;
}
.alert_image {
  height: 27px;
  cursor: pointer;
  margin-left: 2px;
}
.alert_tooltip {
  white-space: pre-line;
}
.profile_Row_button {
  margin: 6px;
}
