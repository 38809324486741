.processAnalyzerCreate_drawer_head {
  background-color: #0f183f;
  display: flex !important;
  justify-content: space-between;
  padding: 15px;
  color: white;
  font-weight: 500;
}
.procees_list_body_main {
  overflow: hidden;
  height: calc(100vh - 124px);
}

.procees_list_paper_right_main {
  height: calc(100vh - 150px);
}
.procees_list_paper_right_header_prop {
  font-size: x-large;
  color: grey;
}
.procees_list_paper_right_display {
  display: flex;
}
.procees_list_paper_right_meta_main {
  font-size: small;
  font-weight: 500;
  width: 100%;
}


.procees_list_paper_right_meta_header {
  margin-top: 5px;
  border-radius: 3px;
  border: 2px solid #cac7c7;
  height: 30px;
  padding: 5px;
}
.procees_list_paper_right_message {
  overflow: auto; 
  padding: 5px 0px;
  margin-bottom: 5%;
  display: flex;
  flex-wrap: wrap;
}
.procees_list_paper_right_message_redbody {
  overflow: auto;
  padding: 5px;
  background-color: #ff000040;
  border-radius: 3px;
  border-left: 2px solid red;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
  height: 225px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.procees_list_paper_right_message_main {
  font-size: small;
  font-weight: 500;
  width: 50%;
}

.procees_list_paper_right_message_header {
  margin-top: 5px;
  border-radius: 3px;
  border: 2px solid #cac7c7;
  height: 30px;
  padding: 5px;
  margin-left: 5px;
}

.procees_list_paper_right_message_greenbody {
  overflow: auto;
  padding: 5px;
  background-color: #00ff0030;
  border-radius: 3px;
  border-left: 2px solid green;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  height: 225px;
  margin-bottom: 5px;
  margin-left: 5px;
}
.procees_list_paper_right_meta_greenbody {
  overflow: auto;
  padding: 5px;
  background-color: #00ff0030;
  border-radius: 3px;
  border-left: 2px solid green;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  height: 225px;
  margin-bottom: 5px;
} 

.procees_list_customPassedMessage{
  /* font-weight: bold; */
  background-color: #00ff0030;
  padding: 10px;
  margin: 5px 0px;
  border: 2px solid #00ff0030; 
}
.procees_list_customFailedMessage{
  /* font-weight: bold; */
  background-color: #ff000040;
  padding: 10px;
  margin: 5px 0px;
  border: 2px solid #ff000040; 
}
.procees_list_messageData{
  padding: 0px 10px 0px 10px;
  width: 50%;
}

.procees_list_paper_right_msghead {
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px dashed;
  font-weight: bold;
}

.procees_list_paper_right_msgheaderCenterStart {
  font-weight: bold;
  text-align: center;
}

.procees_list_paper_right_msgheaderCenterEnd {
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px dashed;
  font-weight: bold;
  text-align: center;
}


.procees_list_paper_right_subheader_contResultRed {
  font-size: small;
  font-weight: 500;
  background-color: red;
  color: white;
  border-radius: 15px;
  padding: 2px 10px;
  margin: 5px;
}

.procees_list_paper_right_subheader_contResultGreen {
  font-size: small;
  font-weight: 500;
  background-color: green;
  color: white;
  border-radius: 15px;
  padding: 2px 10px;
  margin: 5px;
}

.procees_list_paper_right_subheader_main {
  font-size: small;
  font-weight: 500;
}
.procees_list_paper_right_subheader_Descrip {
  margin-top: 5px;
  border-radius: 3px;
  border: 2px solid #cac7c7;
  height: 30px;
  padding: 5px;
}
.procees_list_paper_right_subheader_contDescripGreen {
  overflow: auto;
  padding: 5px;
  background-color: #00ff0030;
  border-radius: 3px;
  border-left: 2px solid green;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  height: 65px;
}
.procees_list_paper_right_header_cont {
  font-size: x-large;
}
.processAnalyzerCreate_drawer_body {
  padding: 1rem;
}
.processAnalyzerCreate_drawer_body_row {
  padding: 10px;
}
.processAnalyzerCreate_drawer_btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 5px;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: flex-end;
}

.processAnalyzerCreate_drawer_body_heading {
  font-weight: bold;
}

.processAnalyzerCreate_drawer_body_heading_body {
  font-weight: bold;
  border-bottom: 1px solid #0f183f;
}

.processAnalyzerCreate_paper_body {
  height: calc(100vh - 245px);
  overflow: auto;
}

.processAnalyzerCreate_paper_subcontent {
  border-bottom: 1px solid #0f183e2b;
  margin-bottom: 5px;
}

.processAnalyzerCreate_paper_subcontent_rule {
  font-size: small;
  color: gray;
}
.cardTitleGreen{
  height: 55px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
