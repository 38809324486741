.rule_list_body {
  padding: 5px 10px;
  height: calc(100vh - 145px);
  overflow: auto;
}

.rule_list_body_row {
  margin-top: 10px;
}

.rule_list_header {
  height: 60px;
  background: #ffffff;
}

.rule_list_header_title {
  font-size: x-large;
  padding: 10px;
}

.rule_list_header_button {
  float: right;
  margin: 5px;
}

.rule_list_noData_body {
  text-align: center;
  background-color: white;
  height: calc(100vh - 145px);
  margin-top: 10px;
}

.rule_list_noData_body_image {
  height: 125px;
  margin-top: 10%;
}

.rule_list_noData_body_content {
  color: #b4b5b3;
  font-size: 23px;
  padding: 15px;
}

/* Display rules */


.rule_desc {
  font-size: 1rem;
  color: black;
  padding: 0rem 1rem 0.5rem 1rem;
  height: 75px;
}
.rule_info {
  padding: 0rem 1rem 0rem 1rem;
  font-size: 1rem;
  color: black;
}

.ruleList_body_main {
  overflow: auto;
  height: calc(100vh - 124px);
  padding: 16px 8px;
}

.ruleList_body_tile_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
}


/* New Card Design Enhancement: START*/
.cardMainBox{
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}
 
.cardMainBox .cardDeactivatedContent{
  background-color: #dedede;
  opacity: 0.3;
}
.cardMainBox .cardActivatedContent, .cardMainBox .cardDeactivatedContent{
  border-radius: 12px;
  overflow: hidden;
}
.cardActivatedContent hr, .cardDeactivatedContent hr{
  margin-top: 0 !important;
  margin-bottom: 0 !important; 
}
.cardheaderContent {
  height: 80px;
  background-color: #0f183f;
  padding: 16px;
  display: flex;
  align-items: center;
}
.cardheaderText{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.04rem;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardMiddleContent{
  padding: 5px 16px;
}
.cardMiddleContentBlock{
  padding: 7px 0px;
}
.cardMiddleContentBlockTitle {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.04rem;
  color: #919294;
}
.cardMiddleContentDescriptonBlock{
  height: 55px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardMiddleContentDescriptionValue{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.02rem;
  color: #000000;
  max-height: 55;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardMiddleContentBlockValue{
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 65%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.02rem;
  color: #000000;
}
.cardFooterContent {
  padding: 16px;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e8edf3;
  height: 56px;
}
.cardFooterContent a {
  text-decoration: underline !important;
}
.cardFooterContentIcon{
  position: absolute;
  right: 20px;
}
.MuiPaper-rounded{
  border-radius: 12px;
}
/* New Card Design Enhancement: END*/
