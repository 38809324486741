.addUser_Row_header {
  margin: 10px 25px;
}
.addUser_Row_body {
  margin: 10px 10px;
}

.addUser_Row_button {
  float: right;
  margin: 10px 10px;
}

.addUser_Row_error {
  color: red;
  margin: 10px 25px;
}

.addUser_Row_Company_header {
  font-size: small;
}

.addUser_Row_Company_subheader {
  margin: 5px;
}
