@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: local('Roboto'),
    url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: local('Roboto'),
    url(./assets/fonts/Roboto/Roboto-Black.ttf) format('opentype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.divStyle {
  border-style: outset;
  width: 100%;
  height: 50%;
  padding: 100px;
  background-color: #87aacc;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* body {
    background-color: #F7F9FC;
  } */

.Rule-Builder {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* background-image: url('./Background.jpeg');
    opacity: 0.5; */

  /* border-radius: 10px;
    padding: 35px;
    background-color: white;
    border: 1px solid #E0E2E4; */
}

.basic-multi-select {
  width: 88%;
  /* padding-left: 20px; */
  /* float: left; */
}

.select-box {
  margin-top: 20px;
  /* margin-left: 30px;  */
}

/* .select-box label {
    margin-top: 20px;
    padding-right: 14px;
   } */

.select-box input {
  float: left;
}
/* .select-box button {
      float: left;
      margin-left: 25px;
      height: 34px;
      padding: 0px 20px;
  } */

.rule-list-block {
  /* margin-top: 5%; */
  text-align: left;
  /* margin-left: 3%; */
  /* border: 1px solid; */
  /* padding: 30px; */
  font-size: 14px;
  /* width: 57%; */
}

#rules {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.stageHeader {
  width: 24%;
}

.stageRow {
  display: inline-flex;
  width: 100%;
}
#rules td,
#rules th {
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 16px;
}

#rules td span {
  display: block;
  /* background: lightblue; */
  padding: 10px 0px;
  /* border-bottom: 1px solid silver; */
  text-align: center;
}

.stageCode {
  padding: 0px !important;
}

/* .ruleList {
    display: inline-block;
  
  } */

.ruleList input {
  float: left;
}
.ruleList span {
  float: left;
}
#rules tr:nth-child(even) {
  background-color: #f2f2f2;
}

#rules tr:hover {
  background-color: #ddd;
}

#rules th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0f183f;
  color: white;
}

#rules span {
  display: block;
}

.ruleName {
  display: block;
  height: 52px !important;
}

.link {
  color: #0f183f !important;
  text-decoration: underline;
  cursor: pointer;
}

.Rule-Builder input[type='checkbox'] {
  width: 20px;
  margin-top: 0px;
  margin-right: 10px !important;
}

.filter-block span,
.object-block span {
  /* float: left;
    width: 32%;
    margin-left: 2%; */
  /* margin-top: 3%; */
  display: block;
}

.backImage {
  position: absolute;
  right: 0%;
  /* top: 15%; */
  z-index: -1;
  opacity: 0.4;
}

.filter-block p {
  padding-top: 10px;
}

.filter-block,
.object-block {
  margin-top: 5%;
  display: block;
  border: 2px solid grey;
  width: 98%;
  padding: 3% 2%;
  border-radius: 5px;
}

.heading {
  font-weight: 600;
  font-size: 20px;
  top: 0;
  margin-top: -4.5%;
  background: white;
  width: max-content;
  /* color: blue; */
  color: grey;
  padding: 0% 1%;
}

@media only screen and (max-width: 1100px) {
  .heading {
    margin-top: -5.5%;
  }
}

.ruleList {
  margin: 1%;
}

.ruleList input[type='checkbox'] {
  box-sizing: border-box;
  /* margin: 1%; */
  height: 27px;
  width: 27px;
}

.ruleList span {
  margin-left: 1%;
}

#tooltipPattern {
  display: block;
  color: white;
  background: black;
  float: left;
  margin-left: 10px;
  padding: 1px 10px;
  border-radius: 20px;
  text-align: center;
}

.patternCol label {
  float: left;
}

.filter-block .basic-multi-select,
.object-block .basic-multi-select {
  /* float: left; */
  width: 100%;
}

.isAttribute label {
  width: 10%;
  /* float: none; */
}
.isAttribute {
  width: 20% !important;
}

/* .filter-block .isAttribute label{
    float: none;
  } */

.filter-block button {
  /* display: block; */
  margin-left: 25px;
  height: 34px;
  padding: 0px 20px;
  margin-top: 1%;
  color: '#0f183f';
}

.object-block button {
  /* display: block; */
  margin-left: 25px;
  height: 34px;
  padding: 0px 20px;
  margin-top: 1%;
}

.objectLabel {
  padding: 10px;
}

/* .ruleDefination {
    margin-left: 7%;
    margin-top: 5%;
  } */

.rootNode {
  display: block;
  float: none !important;
}

.rootNode label {
  padding-right: 10px;
}

.css-yk16xz-control,
.select__control {
  border: 1px solid #0f183f !important;
  height: 52px !important;
  /* height: 45px !important; */
}

.saveButton,
.cancelButton {
  padding: 15px 20px;
  margin: 4px;
  margin-top: 3%;
  width: 7%;
  text-align: center;
}

.cancelButton {
  background-color: white !important;
  border: 1px solid #0f183f;
  color: #0f183f;
}

.horizontal-line {
  border-top: 2px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: none;
}

.filterHeading {
  color: #0f183f;
  text-decoration: underline;
}

.error {
  display: inline-table;
  color: red;
  font-size: 12px;
  padding: 5px;
  /* float: left; */
}

.deleteButton {
  width: 8% !important;
  color: gray;
  margin-top: 50px !important;
}

.red {
  color: red;
}

.pattern {
  display: inline-block;
  padding: 2px;
  width: 3%;
  margin: 1%;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  color: white;
  font-size: medium;
  background-color: black;
  border: black 1px solid;
}

.patternLabel {
  margin: 1% !important;
  display: inline-block !important;
  margin-right: 3px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 260px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -140px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.selectStyle {
  width: 50%;
  color: #fff;
  background-color: #3066db;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding-left: 15px;
}
.defaultTextBox {
  padding: 0;
  height: relative;
  left: 0;
  outline: none;
  border: 1px solid #cdcdcd;
  border-color: rgba(0, 0, 0, 0.15);
  background-color: white;
  font-size: 16px;
}
.adv {
  width: 526px;
  margin-right: -4px;
}
.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 50%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}
/* .modal-dialog{
    top: 20%;
    position: fixed;
  } */

.text-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 50%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.myButton {
  box-shadow: 3px 4px 0px 0px #1564ad;
  background: linear-gradient(to bottom, #79bbff 5%, #378de5 100%);
  background-color: #79bbff;
  border-radius: 5px;
  border: 1px solid #337bc4;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  font-weight: bold;
  padding: 11px 20px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #528ecc;
}

.myButton:hover {
  background: linear-gradient(to bottom, #378de5 5%, #79bbff 100%);
  background-color: #378de5;
}
.myButton:active {
  position: relative;
  top: 1px;
}

/*  */
.ruleListConatiner {
  margin-top: 10px;
  height: 350px;
  display: inline-block;
  overflow-y: auto;
}

.customform {
  padding-top: 10px !important;
}
.customform input {
  width: 80% !important;
  padding: 2px 5px;
}
.clear {
  clear: both;
}

.customtable tr > :nth-child(1) {
  width: 4rem;
  text-align: center;
}

.custom-analyzer-table tr > :nth-child(3) {
  width: 8rem !important;
  text-align: center;
}

.simple-tabpanel {
  padding-top: 100px;
}

.deleteicon {
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}

.modal.show .modal-dialog {
  transform: none;
  padding-top: 100px;
}

/* @media (min-width: 576px) {
  .modal-dialog {
    max-width: 75% !important;
  }
} */

#dashboard_drawer > .MuiDrawer-paperAnchorLeft {
  top: 64px !important;
}

#dashboard_drawer > .MuiBackdrop-root {
  top: 64px !important;
}

#superAdminNav > .nav {
  background-color: #0f183fad;
  color: white;
  box-shadow: -1px 1px 10px black;
  border: 1px solid #5c627d;
  cursor: pointer;
}

#superAdminNav > .nav > .nav-item > a {
  color: white;
  cursor: pointer;
}

/*over ride start*/
body {
  font-size: 14px;
  overflow-y: hidden;
}
/*over ride end*/

/*Scroll bar start*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0f183f;
}
/*Scroll bar end*/

/*Table start*/
#b_table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
#b_table td,
#b_table th {
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 16px;
}

#b_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#b_table tr:hover {
  background-color: #ddd;
}

#b_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0f183f;
  color: white;
}
/*Table end*/

/*Genric Material UI overide css */
/* button {
  text-transform: none !important;
} */

.MuiSvgIcon-root {
  cursor: pointer;
}
.PrivateTabIndicator-colorSecondary-10 {
  background-color: #f9f9f9 !important;
  height: 5px !important;
}

.MuiButtonBase-root {
  font-size: 15px !important;
  font-weight: bold !important;
}

.MuiStepper-root {
  padding: 10px !important;
  box-shadow: 2px 0px 10px grey !important;
}

.MuiStepper-root
  .MuiStep-root
  .MuiStepLabel-root
  .MuiStepLabel-labelContainer
  .MuiTypography-root {
  margin-top: 5px !important;
}

.MuiAlert-standardInfo {
  border: 1px solid #66b9f7;
}

.MuiTabs-indicator {
  background-color: #ffffff !important;
}

/* Genric Material UI overide css end */

/*Reusable Class*/
.float-Right {
  float: right;
}

.float-Left {
  float: left;
}

/*Reusable Class*/

.rule_avtar {
  font-size: 28px;
  background: rosybrown;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  height: 60%;
  padding: 30% 0%;
}

#mui-rte-root {
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

#mui-rte-editor {
  padding: 15px;
}

.MuiLink-underlineHover {
  text-decoration: underline !important;
  color: #1f1fde !important;
}
.MuiPaper-rounded {
  border-radius: 12px !important;
}

.RuleDetailsDrawer_tab__2gQ-s {
  height: auto !important;
  max-height: 50vh;
  overflow-y: auto;
}
.processAnalyzer_stbtn .MuiFormLabel-root {
  color: #43b0f1;
}

.processAnalyzer_stbtn .MuiOutlinedInput-notchedOutline {
  border-color: #43b0f1;
}

.processAnalyzer_stbtn .MuiOutlinedInput-notchedOutline:focus {
  border-color: #43b0f1;
}

.processAnalyzer_stbtn .MuiOutlinedInput-notchedOutline:hover,
.processAnalyzer_stbtn .MuiOutlinedInput-notchedOutline:focus {
  border-color: #43b0f1;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #eabd00;
  border-radius: 50%;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

#gridtest {
  height: 100% !important;
}

.cd-pi-row {
  padding: 16px 8px 31px;
  border-radius: 12px;
  box-shadow: 0 2px 2px 0 var(--black-30);
  background-color: #ffffff;
}

.header {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #000000;
}
.divider {
  height: 1px;
  border: solid 1px #dcdddc;
  width: 100%;
}
.fr {
  float: right;
}
.fl {
  float: left;
}

.cd-switch {
  margin-top: -8%;
}


.showMoreDots {
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* after 2 line show ... */
    -webkit-box-orient: vertical;
}
