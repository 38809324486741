#categoryChartParent {
  float: left;
  margin-left: 0%;
  margin-top: 1%;
}
#categoryChartParent .legend {
  float: right;
  overflow-y: scroll;
}
#categoryChartChild .legend {
  float: right;
  margin-left: 30px !important;
}
#OverallChartParent,
#OverallChartChild {
  display: block;
}
#OverallChartChild {
  margin-top: 1%;
  margin-left: 25%;
}
#OverallChartParent {
  margin-left: 10%;
  margin-top: 20%
}
#chart .legend {
  margin-left: 30px !important;
  margin-top: 5% !important;
}
#OverallChartChild .legend {
  float: right;
  margin-left: 30px !important;
  height: 200px;
  overflow-y: scroll;
}

#categoryChartParentLegend {
  overflow-y: scroll;
  height: 320px;
  margin-top: 10%;
}

#categoryChartChild {
  margin-top: 25%;
}
.fl-l {
  float: left;
}
#categoryChartChild svg {
  margin-top: 15%;
}

#OverallChartChildLegend {
  overflow-y: scroll;
  height: 250px;
  margin-top: 20%;
}
#chart .legend {
  float: right;
  margin-left: 30px !important;
  height: 200px;
  overflow-y: scroll;
}
.arc text {
  font: 10px sans-serif;
  text-anchor: middle;
}

.arc path {
  stroke: #fff;
}

.h-300 {
  height: 300px;
}

.h-320 {
  height: 320px;
}
.h-775 {
  height: 775px;
}
.mlp-13 {
  margin-left: 13%;
}
.mlp-30 {
  margin-left: 30%;
}
.mlp-0 {
  margin-left: 0%;
}
.mlp--10 {
  margin-left: -10%;
}
.mtp-5 {
  margin-top: 5%;
}
.mtp-10 {
  margin-top: 10%;
}
.pt-4 {
  padding-top: 4%;
}
.pt-8 {
  padding-top: 8%;
}
.br-1 {
  border-right: 1px solid #a9acaf;
}
.bb-1 {
  border-bottom: 1px solid #a9acaf;
}
.b-1 {
  border: 1px solid #a9acaf;
}
.b-r-4 {
  border-radius: 4px;
}
.mr-1 {
  margin-right: 1%;
}
.mtp-2 {
  margin-top: 2%;
}
.mtp-50 {
  margin-top: 50%;
}
.chart_heading {
  font-size: 18px;
  margin-left: 2px;
  margin: 1% 1% 1% 0%;
}
.slice-title {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}