.rule_properties_deleteicon {
  float: left;
  padding-left: 6%;
  padding-top: 6%;
}

.rule_properties_customcard {
  margin-left: 0px !important;
  border: 1px solid rgb(182, 178, 178);
  border-radius: 5px;
}

.rule_properties_right_imagediv {
  text-align: center;
  height: 250px;
}

.rule_properties_right_image {
  top: 80px;
  position: relative;
  width: 130px;
  height: 130px;
}

.rule_properties_right_header {
  color: #00519f;
  text-align: center;
  font-weight: bolder;
}

.rule_properties_right_subheader {
  color: #a0a2aa;
  text-align: center;
  font-size: 12px;
  padding: 15px 30px;
}
